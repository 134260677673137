import * as styles from "./SliderArrow.module.css";

import { ISVGColours } from "../../../../models/ComponentProps/SVGProps.model";
import React from "react";
import SVGIcon from "../../../SVGs/SVGIcon";

const SliderArrow = ({
  direction,
  colour,
  onClick,
  style,
  arrowClassName = "",
  className = "",
  svgWidth = 10.5,
  svgHeight = 16,
  useSVGSizeForButtonSize = false,
}: {
  direction: "left" | "right";
  colour?: ISVGColours;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  arrowClassName?: string;
  className?: string;
  style?: React.CSSProperties;
  svgWidth?: number;
  svgHeight?: number;
  useSVGSizeForButtonSize?: boolean;
}) => {
  let styleFinal = { ...style };
  if (useSVGSizeForButtonSize) {
    const buttonSize = svgWidth > svgHeight ? svgWidth : svgHeight;
    styleFinal = { ...styleFinal, width: buttonSize, height: buttonSize };
  }
  return (
    <button
      className={`${className} ${
        direction === "left" ? styles.sliderArrowLeft : styles.sliderArrowRight
      } ${arrowClassName}`}
      style={{ ...styleFinal }}
      onClick={onClick}
    >
      <SVGIcon
        colour={colour}
        height={svgHeight}
        width={svgWidth}
        icon={direction === "left" ? "arrow-left" : "arrow-right"}
      />
    </button>
  );
};

export default SliderArrow;
