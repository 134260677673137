import * as styles from "./FooterCTA.module.css";

import ButtonLink from "../../../../UI/Button/ButtonLink/ButtonLink";
import { ButtonStyle } from "../../../../../models/ComponentProps/ButtonProps.model";
import React from "react";
import usePhoneAndAccountCode from "../../../../../hooks/usePhoneAndAccountCode";

const FooterCTA = () => {
  const { phoneNumber } = usePhoneAndAccountCode();
  return (
    <section className={styles.footerCTAContainer}>
      <div className="inner">
        <div className={styles.footerCTACall}>
          <a
            href={`tel:${phoneNumber.replace(/\s/g, "")}`}
            title={`Call ${phoneNumber}`}
            className="h3"
          >
            Call now, we&apos;re here to help <span>{phoneNumber}</span>
          </a>
        </div>
        <div className={styles.footerCTAButtons}>
          <ButtonLink
            link="/request-a-callback"
            title="Request a callback"
            buttonStyle={ButtonStyle.Secondary}
          />
          <ButtonLink link="#phone#" title="Call #phone#" />
        </div>
      </div>
    </section>
  );
};

export default FooterCTA;
