import * as styles from "./Accreditations.module.css";

import { GatsbyImage, getImage } from "gatsby-plugin-image";
import {
  defaultSliderResponsiveObject,
  defaultSliderResponsiveSettings,
  defaultSliderSettings,
} from "../../../../../models/Slider.model";

import React from "react";
import Slider from "react-slick";
import SliderArrow from "../../../../General/Slider/SliderArrow/SliderArrow";
import SliderDots from "../../../../General/Slider/SliderDots/SliderDots";
import useAccreditations from "../../../../../hooks/useAccreditations";

const Accreditations = () => {
  const {
    allAccreditations: { edges: accreditations },
  } = useAccreditations();

  let sliderSettings = defaultSliderSettings;
  sliderSettings = {
    ...sliderSettings,
    slidesToShow: accreditations.length,
    slidesToScroll: accreditations.length,
    responsive: [
      {
        ...defaultSliderResponsiveObject,
        settings: {
          ...defaultSliderResponsiveSettings,
          appendDots: (dots) => {
            return (
              <SliderDots
                dots={dots}
                dotsClassName={styles.accreditationDots}
              />
            );
          },
          nextArrow: <SliderArrow direction="right" colour="green" />,
          prevArrow: <SliderArrow direction="left" colour="green" />,
        },
      },
    ],
  };

  return (
    <section className={styles.accreditationContainer}>
      <div className="inner">
        <Slider {...sliderSettings}>
          {accreditations.map(({ node: accreditation }, index) => {
            const logo = getImage(accreditation.logo.imageFile);
            return (
              <div
                className={styles.accreditationItem}
                key={`accrediationItem_${index}`}
              >
                {logo && (
                  <GatsbyImage image={logo} alt={accreditation.logo.title} />
                )}
              </div>
            );
          })}
        </Slider>
      </div>
    </section>
  );
};

export default Accreditations;
