import * as styles from "./Footer.module.css";

import Accreditations from "./Accreditations/Accreditations";
import { Cookies } from "react-cookie-consent";
import FooterCTA from "./CTA/FooterCTA";
import FooterLinks from "./FooterLinks/FooterLinks";
import React from "react";
import { useShowCookieBar } from "../../../../hooks/useShowCookieBar";

const Footer = ({ showEmergencyBar }: { showEmergencyBar: boolean }) => {
  const cookieBar = Cookies.get("gatsby-gdpr-google-analytics");
  const { isCookieBarDismissed } = useShowCookieBar();
  return (
    <footer
      className={`${styles.footer} ${
        showEmergencyBar ? styles.footerWithEmergencyBar : ""
      } ${
        cookieBar === undefined && !isCookieBarDismissed
          ? styles.footerWithCookieBar
          : ""
      }`}
    >
      <Accreditations />
      <FooterCTA />
      <FooterLinks />
    </footer>
  );
};

export default Footer;
