import * as styles from "./FooterLinks.module.css";

import FooterNav from "../FooterNav/FooterNav";
import { Link } from "gatsby";
import React from "react";
import SVGIcon from "../../../../SVGs/SVGIcon";
import { StaticImage } from "gatsby-plugin-image";
import useSiteMetadata from "../../../../../hooks/useSiteMetadata";

const FooterLinks = () => {
  const { phoneNumber } = useSiteMetadata();
  return (
    <section className={styles.footerLinksContainer}>
      <div className="inner">
        <div className={styles.footerLogo}>
          <Link to="/" title="Rightio - Home Page">
            <SVGIcon
              icon="rightio-logo"
              colour="white"
              width={172}
              height={50}
              className={styles.footerLogoImage}
            />
          </Link>
        </div>
        <div className={styles.footerAddress}>
          <strong className={styles.footerAddressCompany}>RIGHTIO&reg;</strong>
          <address>
            Trigate, 210 - 222 Hagley Road West,
            <br />
            Oldbury, B68 0NP.
          </address>
          <p>Registration No.: 12523871</p>
          <p>
            <a
              href={`tel:${phoneNumber.replace(/\s/g, "")}`}
              title={`Call ${phoneNumber}`}
            >
              {phoneNumber}
            </a>
          </p>
          <div className={styles.footerAddressImages}>
            <StaticImage
              src="../../../../../images/gas-safe-register-triangle-logo.png"
              alt="Gas safe register triangle logo"
              height={50}
            />
            <StaticImage
              src="../../../../../images/corgi-logo.png"
              alt="Corgi logo"
              height={50}
            />
          </div>
        </div>
        <nav className={styles.footerLinksNav} aria-label="Our services">
          <FooterNav
            title="Our services"
            links={[
              {
                to: "/heating-and-plumbing/plumbing",
                title: "Plumbing",
              },
              {
                to: "/heating-and-plumbing/boilers",
                title: "Boilers",
              },
              {
                to: "/heating-and-plumbing/heating",
                title: "Heating",
              },
              {
                to: "/heating-and-plumbing/drains",
                title: "Drains",
              },
              {
                to: "/locks",
                title: "Locks",
              },
              {
                to: "/electrical",
                title: "Electrical",
              },
            ]}
          />
        </nav>
        <nav className={styles.footerLinksNav} aria-label="Useful links">
          <FooterNav
            title="Useful links"
            links={[
              {
                to: "/about-us",
                title: "About us",
              },
              {
                to: "/advice-hub",
                title: "Advice Hub",
              },
              {
                to: "/careers",
                title: "Careers",
              },
              {
                to: "/locations",
                title: "Locations",
              },
            ]}
          />
        </nav>
        <div className={styles.footerFinance}>
          <p>
            * Rightio Subcontractors Ltd is an Introducer Appointed
            Representative of Kanda. Kanda is a trading style of Kanda Products
            & Services Ltd, Forward House, 17 High Street, Henley-in-Arden, B95
            5AA, registered in England (11330964), authorised and regulated by
            the Financial Conduct Authority (firm reference number 920795).
            Kanda is acting as a credit broker offering finance products from a
            panel of lenders Credit is subject to status.
          </p>
          <p>
            <em>
              Representative example: 11.9% APR Representative based on a loan
              of £5000 repayable over 60 months at an interest rate of 11.9% pa
              (fixed). Monthly repayment of £109.45. Total amount payable
              £6567.16.
            </em>
          </p>
        </div>
        <div className={styles.footerCopyright}>
          <p>
            &copy; {new Date().getFullYear()} Rightio. All rights reserved.
            Rightio is responsible for The content displayed on the website.{" "}
            <Link to="/terms-and-conditions" title="Terms and Conditions">
              Terms and conditions.
            </Link>{" "}
            <Link to="/privacy-policy" title="Privacy Policy">
              Privacy policy.
            </Link>{" "}
            <Link to="/accessibility" title="Accessibility">
              Accessibility.
            </Link>{" "}
            <Link to="/complaints-procedure" title="Complaints Procedure">
              Complaints procedure.
            </Link>
          </p>
        </div>
      </div>
    </section>
  );
};

export default FooterLinks;
